import cn from 'classnames'
import React from 'react'
import Select, { MultiValue, Props, SingleValue } from 'react-select'

export type SelectInputProps = {
  className?: string
  defaultValue?: Props<Option>['defaultValue']
  isInvalid?: boolean
  multi?: boolean
  name: string
  options: Option[]
  placeholder?: string
  onChange?: Props<Option>['onChange']
  closeMenuOnSelect?: boolean
  disabled?: boolean
  value?: SingleValue<Option> | MultiValue<Option>
  isClearable?: boolean
}

export type Option = {
  label: string
  value: string | number
}

export const SelectInput: React.VFC<SelectInputProps> = (props) => {
  const {
    defaultValue,
    isInvalid,
    multi,
    name,
    options,
    placeholder,
    onChange,
    closeMenuOnSelect,
    disabled,
    className,
    value,
    isClearable = false,
  } = props

  return (
    <div className="relative" data-testid="select-input">
      <Select
        className={cn(
          'react-select',
          { 'react-select-invalid': isInvalid },
          className
        )}
        classNamePrefix="react-select"
        closeMenuOnSelect={closeMenuOnSelect}
        defaultValue={defaultValue}
        isClearable={isClearable}
        isMulti={multi}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="absolute"
        name={name}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        isDisabled={disabled}
        value={value}
      />
    </div>
  )
}
